import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import FAQ from '../../molecules/FAQ'

import styles from '../FAQSection/styles.module.scss'

const FAQSection = ({ className, heading, subheading, questions }) => {
  return (
    <section
      className={classNames(styles.faqSection, className)}
      itemScope
      itemType="http://schema.org/FAQPage"
    >
      <div className={styles.subheadingWrapper} data-testid="faq-subheading">
        {subheading}
      </div>
      <div className={styles.headingWrapper} data-testid="faq-heading">
        {heading}
      </div>

      <div className={styles.faqWrapper}>
        {questions.map((question, index) => (
          <FAQ
            index={index}
            key={question.question}
            className={styles.faq}
            question={question.question}
            answer={question.answer}
          />
        ))}
      </div>
    </section>
  )
}

FAQSection.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subheading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
    })
  ).isRequired,
}

export default FAQSection
