import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Heading from '@afs/components/Heading'

import styles from './styles.module.scss'

const FAQ = ({ className, question, answer, index }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <div
      className={className}
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <button
        data-testid={`button-${index}`}
        className={styles.button}
        itemProp="name"
        aria-controls={`faq-answer-${index}`}
        aria-expanded={!isCollapsed}
        onClick={() => setIsCollapsed((prevState) => !prevState)}
      >
        <Heading
          className={styles.question}
          level={4}
          data-testid="faq-question"
        >
          {question}
        </Heading>
        <span
          className={classNames(styles.icon, {
            [styles.expandedAnswerIcon]: !isCollapsed,
          })}
        ></span>
      </button>
      <div
        id={`faq-answer-${index}`}
        className={classNames(styles.answer, {
          [styles.answerCollapsed]: isCollapsed,
        })}
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
        data-testid="faq-answer"
      >
        <div className={styles.text} itemProp="text">
          {answer}
        </div>
      </div>
    </div>
  )
}

FAQ.propTypes = {
  className: PropTypes.string,
  question: PropTypes.string.isRequired,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  index: PropTypes.number.isRequired,
}

export default FAQ
