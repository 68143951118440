import React from 'react';
import classNames from 'classnames';
import config from '../../../config';
import LazyImage from '../LazyImage';
import styles from './styles.module.scss';
interface TrustpilotMicrostarRatingProps {
  className?: string;
}
const TrustpilotMicrostarRating = ({
  className
}: TrustpilotMicrostarRatingProps) => {
  const trustpilotLogo = 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/trustpilot-logo-great.png';
  return <LazyImage className={classNames(styles.rating, className)} alt="Accommodation for Students is rated Great on Trustpilot" dataSrc={`${config.imgApiUrl}h=28/${trustpilotLogo}`} dataSrcSet={`${config.imgApiUrl}h=28/${trustpilotLogo} 1x, ${config.imgApiUrl}h=28,dpr=2/${trustpilotLogo} 2x`} data-sentry-element="LazyImage" data-sentry-component="TrustpilotMicrostarRating" data-sentry-source-file="index.tsx" />;
};
export default TrustpilotMicrostarRating;